import { useTranslation } from "react-i18next";
import { TextField, CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import createCache from "@emotion/cache";
import { useEffect, useState, useRef, useContext } from "react";
import ".././stepsNum/stepsNumCss/FullNameAndPhone.css";
import parsePhoneNumber from "libphonenumber-js";
import { DataContext } from "../../../../../dataContext";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Margin, NoBackpackSharp } from "@mui/icons-material";
import { reverse } from "lodash";

export default function FullNameAndPhone({}) {
  const {
    setNameAndPhone,
    nameAndPhone,
    errorsPhoneName,
    setErrorsPhoneName,
    setContinueToSummary,
  } = useContext(DataContext);

  const ref = useRef();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const { t, i18n } = useTranslation();

  const InputLabelProps = {
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "right",
    color: "#4F5976",
  };

  const onChange = ({ target: { value } }) => {
    let valid = true;
    const newPhoneNumber = parsePhoneNumber(value, "IL");
    if (newPhoneNumber) {
      valid = newPhoneNumber.isValid();
      if (valid) {
        value = newPhoneNumber.number;
      }
    }

    nameAndPhone.phoneNumber = value;
    setNameAndPhone({ ...nameAndPhone });
    const phone = "phone";
    const bool = !valid;
    setErrorsPhoneName((prevState) => ({
      ...prevState,
      [phone]: bool,
    }));
  };

  const handleChange = (e) => {
    nameAndPhone.fullName = e.target.value;
    setNameAndPhone({
      ...nameAndPhone,
    });
  };

  const handleChange2 = (e) => {
    if (e.target.checked) {
      nameAndPhone.regulations = e.target.checked;
      setNameAndPhone({
        ...nameAndPhone,
      });
    } else {
      nameAndPhone.regulations = e.target.checked;
      setNameAndPhone({
        ...nameAndPhone,
      });
    }
    //setIsSubscribed((current) => !current);
  };

  const isRTL = (s) => {
    if (s !== "") {
      var ltrChars =
          "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
          "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
        rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
        rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

      return rtlDirCheck.test(s);
    }
    return true;
  };

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  return (
    <div className="infoSteps">
      <div
        className="flex_header_nameAndPhone"
        style={{ marginBottom: "69px" }}
      >
        <h1 className="h1Step"> {t("STEPS_NUM_4_ABOUT_YOU_HEADER_LINE_1")} </h1>{" "}
        <p> {t("STEPS_NUM_4_ABOUT_YOU_HEADER_LINE_2")} </p>{" "}
      </div>{" "}
      <div className="litleAboutTextField">
        <CacheProvider
          value={
            ltrTheme.direction === "ltr" && isRTL(nameAndPhone.fullName)
              ? cacheRtl
              : cacheLtr
          }
        >
          <CssBaseline />
          <div>
            <TextField
              // onSelect={selectNamePhone}
              label={t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME")}
              id="outlined-size-small"
              autoComplete="off"
              onFocus={() => setErrorsPhoneName(false)}
              error={!!errorsPhoneName.name}
              style={{ width: isTabletOrMobile && "100%" }}
              value={nameAndPhone.fullName}
              onChange={handleChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontSize: "16px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              inputProps={{
                style: {
                  direction:
                    ltrTheme.direction === "ltr" && isRTL(nameAndPhone.fullName)
                      ? "rtl"
                      : "ltr",
                },
              }}
              InputProps={{ style: InputLabelProps }}
              InputLabelProps={{
                style: InputLabelProps,
                shrink: true,
              }}
            />{" "}
          </div>{" "}
          <div style={{ marginTop: "35px" }}>
            <TextField
              inputRef={ref}
              error={!!errorsPhoneName.phone}
              helperText={
                errorsPhoneName.phone &&
                t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")
              }
              value={nameAndPhone.phoneNumber}
              onFocus={() => setErrorsPhoneName(false)}
              style={{ width: isTabletOrMobile && "100%" }}
              onChange={onChange}
              autoComplete="off"
              label={t("STEPS_NUM_4_ABOUT_YOU_INPUT_PHONE")}
              id="outlined-size-small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontSize: "16px",
                  "& fieldset": {
                    direction: ltrTheme.direction === "rtl" ? "ltr" : "ltr",
                  },
                },
              }}
              inputProps={{
                style: {
                  direction:
                    ltrTheme.direction === "ltr" && isRTL(nameAndPhone.fullName)
                      ? "rtl"
                      : "ltr",
                },
              }}
              InputProps={{ style: InputLabelProps }}
              InputLabelProps={{
                style: InputLabelProps,
                shrink: true,
              }}
            />{" "}
            {/* 
                                                  <input
                                                    type="checkbox"
                                                    value={nameAndPhone.regulations}
                                                    onChange={handleChange2}
                                                    id="Terms"
                                                    name="Terms"
                                                  />{" "}
                                                  {t("STEPS_NUM_4_APPROVAL_REGULATION")}
                                                {" "} */}{" "}
            <div
              style={{ marginTop: "35px", width: isTabletOrMobile && "100%" }}
              //style={{ paddingTop: "35px", margin: "auto", width: "85%" }}
            >
              <FormGroup sx={{ width: "50%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={nameAndPhone.regulations}
                      checked={nameAndPhone.regulations}
                      onChange={handleChange2}
                      id="Terms"
                      name="Terms"
                      //width='isTabletOrMobile && "100%"'
                      error={!!errorsPhoneName.regulations}
                      helperText={
                        //errorsPhoneName.regulations &&
                        t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")
                      }
                      sx={{
                        //"& .MuiSvgIcon-root": { margin: 16, fontSize: 10 },
                        //"& .Mui-checked": { margin: 16, fontSize: 10 },
                        margin: reverse,
                      }}
                      //onFocus={() => setErrorsPhoneName(false)}
                    />
                  }
                  label={
                    <div style={InputLabelProps}>
                      {" "}
                      {t("STEPS_NUM_4_APPROVAL_REGULATION")}{" "}
                      <a
                        href="https://getpogo.app/_terms/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("STEPS_NUM_4_REGULATION")}{" "}
                      </a>{" "}
                    </div>
                  }
                />{" "}
                {/* <FormHelperText>
                                                      errorsPhoneName.regulations &&
                                                      t("STEPS_NUM_4_ABOUT_YOU_INPUT_NAME_VALIDPHON")
                                                    </FormHelperText> */}{" "}
              </FormGroup>{" "}
              {/* <Checkbox
                                                    value={nameAndPhone.regulations}
                                                    checked={nameAndPhone.regulations}
                                                    onChange={handleChange2}
                                                    id="Terms"
                                                    name="Terms"
                                                    width="auto"
                                                  />
                                                  {t("STEPS_NUM_4_APPROVAL_REGULATION")} */}{" "}
            </div>{" "}
          </div>{" "}
        </CacheProvider>{" "}
      </div>{" "}
    </div>
  );
}
