import {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
  useStyles,
} from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  CssBaseline,
  StepContext,
  Dialog,
  Alert,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useMediaQuery } from "react-responsive";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import "./stepsNumCss/sliderPrice.css";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles, withStyles, styled } from "@mui/styles";
import time from "../../../images/time.png";
import distance from "../../../images/distance.png";
import numDestination from "../../../images/numDestination.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { DataContext } from "../../../../../dataContext";

export default function SliderPrice({}) {
  const { isLoading, val, setVal, setGetDataFromServer, getDataFromServer } =
    useContext(DataContext);

  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const { t, i18n } = useTranslation();
  const [colors, setColors] = useState("red");
  const [text, setText] = useState("");
  const [minutesToDateTime, setMinutesToDateTime] = useState("");
  const [meterToKm, setMeterToKm] = useState("");
  const ref = useRef(null);
  const clone_price_min = Object.assign({}, getDataFromServer);
  const max = clone_price_min.price_min * 3;
  // console.log(val);
  useEffect(() => {
    const four = (clone_price_min.price_min * 3) / 4;

    if (val < four * 2) {
      setColors("#FF0000");
      setText(t("STEPS_NUM_3_HEADER_LINE_4"));
    }

    if (val > four * 3) {
      setColors("#FFB26B");
      setText(t("STEPS_NUM_3_HEADER_LINE_5"));
    }
    if (val > (four * 7) / 2) {
      setColors("#5BDF60");
      setText(t("STEPS_NUM_3_HEADER_LINE_6"));
    }
  }, [val]);

  function time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    // console.log(minutes);

    setMinutesToDateTime(hours + ":" + minutes);
  }

  let m;
  let km;
  useEffect(() => {
    time_convert(getDataFromServer?.track_minutes);
    m = getDataFromServer?.track_distance;
    // km = Math.round(m / 100) / 10;
    //km = (m / 1000).toFixed(2);
    km = m.toFixed(2);
    setMeterToKm(km);
  }, []);

  const InputLabelProps = {
    textAlign: "right",
    fontFamily: "Heebo",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F5976",
  };

  const themeRtl = createTheme({
    direction: "rtl",
  });

  const themeLtr = createTheme({
    direction: "ltr",
  });

  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const ltrTheme =
    document.documentElement.dir === "rtl"
      ? createTheme({ direction: "rtl" })
      : createTheme({ direction: "ltr" });

  const widthSliderVal = () => {
    if (isTabletOrMobile) {
      return "100%";
    }
    if (isMobile) {
      return "100%";
    }
    return "545px";
  };

  return (
    <div style={{ position: "relative" }} className="infoSteps">
      <div style={{ width: "100%" }}>
        <div
          className="headFlex_slider"
          style={{ marginBottom: isTabletOrMobile && "20px" }}
        >
          <h2 className="h1Step" style={{ lineHeight: isMobile && "34px" }}>
            {t("STEPS_NUM_3_HEADER_LINE_1")}
          </h2>
          <p className="pStep2" style={{ whiteSpace: !isMobile && "nowrap" }}>
            {t("STEPS_NUM_3_HEADER_LINE_2")}
          </p>
        </div>

        <div className="slidecontainer">
          <div className="track_time_results">
            <div className="track_time_results_flex">
              <div className="box_result_data">
                {" "}
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_7")}
                  </span>
                  <img className="icon_data_result" src={time} alt="time" />
                </div>
                <span className="result_data_bold">
                  {" "}
                  {getDataFromServer?.count_orders}
                </span>
              </div>

              <div className="box_result_data">
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_6")}
                  </span>
                  <img
                    className="icon_data_result"
                    src={distance}
                    alt="distance"
                  />
                </div>
                <span className="result_data_bold">{`${meterToKm}km`}</span>
              </div>

              <div className="box_result_data">
                <div className="flexHead_data_result">
                  <span className="track_time_results_span">
                    {t("STEPS_NUM_3_LINE_5")}
                  </span>
                  <img
                    className="icon_data_result"
                    src={numDestination}
                    alt="numDestination"
                  />
                </div>
                <span className="result_data_bold">{minutesToDateTime}</span>
              </div>
            </div>
          </div>
          <div
            className="margin_slider_val"
            style={{
              marginBottom: isTabletOrMobile ? "20px" : "24px",
              width: widthSliderVal(),
            }}
          >
            <div
              style={{
                height: isMobile ? "154px" : "124px",
                width: isMobile && "100%",
                margin: isMobile && "auto",
              }}
            >
              <CacheProvider
                value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
              >
                <ThemeProvider
                  theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
                >
                  <CssBaseline />
                  <TextField
                    autoComplete="off"
                    inputRef={ref}
                    className="fontBolder"
                    label={t("STEPS_NUM_3_HEADER_LINE_3")}
                    id="outlined-size-small"
                    type="number"
                    sx={{
                      position: "relative",
                      width: isTabletOrMobile ? "100%" : "545px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          padding: isMobile ? "0 5rem 0 5rem" : "0 11px",
                          textAlign: isMobile ? "center" : "left",
                          fontFamily: "Heebo",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "15px",
                          lineHeight: "22px",
                          color: "#4F5976",
                          // padding: isMobile ? "0 123px" : "11px"
                          "& legend": {
                            height: isMobile ? "20px" : "11px",
                            "& span": { opacity: isMobile && "1" },
                          },
                        },
                        width: "100%",
                        direction:
                          document.documentElement.dir === "ltr"
                            ? "ltr"
                            : "rtl",
                      },
                      // justifyContent: document.documentElement.dir === "ltr" && "flex-start",
                      "& .MuiOutlinedInput-input": {
                        textAlign: isMobile && "end",
                        fontFamily: "Heebo",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "44px",
                        fontSize: "30px",
                        cursor: "pointer",
                        direction:
                          document.documentElement.dir === "ltr"
                            ? "ltr"
                            : "inherit",
                        width: isMobile ? "100%" : "auto",
                        color: "#0D152E",
                      },
                    }}
                    value={val ? val : clone_price_min.price_min}
                    //  defaultValue={clone_price_min.price_min}
                    onChange={(e) => setVal(e.target.value * 1)}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: clone_price_min.price_min,
                      max: max,
                      step: 1,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            position: "relative",
                            width: isMobile ? "80%" : "6%",
                            direction:
                              document.documentElement.dir === "ltr"
                                ? "ltr"
                                : "inherit",

                            "& .MuiTypography-root": {
                              width: isMobile && "100%",
                              height: "34px",
                              fontFamily: "Arial",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "30px",
                              lineHeight: "34px",
                              color: "#7A7789",
                            },
                            "& fil": {},
                          }}
                        >
                          ₪
                        </InputAdornment>
                      ),
                      style: {
                        flexDirection:
                          isMobile && document.documentElement.dir === "ltr"
                            ? "row"
                            : "row-reverse",
                        justifyContent: !isMobile && "space-between",
                        height: "105px",
                        direction:
                          document.documentElement.dir === "ltr" && isMobile
                            ? "ltr"
                            : document.documentElement.dir === "ltr" &&
                              !isMobile
                            ? ""
                            : "ltr",
                      },
                    }}
                    InputLabelProps={{
                      style: !isMobile
                        ? InputLabelProps
                        : { visibility: "hidden" },
                      shrink: true,
                    }}
                  />
                </ThemeProvider>
              </CacheProvider>
            </div>

            <CacheProvider
              value={ltrTheme.direction === "ltr" ? cacheRtl : cacheLtr}
            >
              <ThemeProvider
                theme={ltrTheme.direction === "ltr" ? themeRtl : themeLtr}
              >
                <div
                  style={{
                    width: isMobile && "100%",
                    margin: isMobile && "0 auto",
                    marginRight: !isMobile && "14px",
                    paddingRight: isMobile && "2rem",
                  }}
                >
                  <Slider
                    sx={{
                      color: colors,
                      "& .MuiSlider-thumb": {
                        color: "583DFF",
                      },
                    }}
                    // defaultValue={clone_price_min.price_min *1}
                    min={clone_price_min.price_min * 1}
                    max={clone_price_min.price_min * 3}
                    value={val}
                    onChange={(e) => {
                      setVal(e.target.value * 1);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />

                  <label className="label_slider">{text}</label>
                </div>
              </ThemeProvider>
            </CacheProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
